@import '../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
}

.mobileInput {
  /* Font */
  composes: h1 from global;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;
  height: 50px;

  /* Borders */
  border: 0;
  border-radius: 0;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
}

.desktopInput {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 50px;
  left: 0;
  /* searchContainer margin-top (94px) + mobileInput height (50px) = 144px */
  min-height: calc(100vh - 144px);
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: var(--topbarHeightDesktop);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.coverHeadingName {
  & h1 {
    color: #222;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 99px;
    margin: 0;

    @media(max-width: 920px) {
      line-height: normal;
      text-align: center;
      font-size: 50px;
    }

    @media(max-width: 768px) {
      font-size: 36px;
      line-height: 44px;
    }

    & span {
      color: #105446;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media(max-width: 920px) {
        line-height: normal;
        text-align: center;
        font-size: 50px;
      }

      @media(max-width: 768px) {
        font-size: 36px;
        line-height: 44px;
      }
    }
  }

  & h4 {
    color: #717171;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;

    @media(max-width: 920px) {
      text-align: center;
      margin-top: 20px;
    }

    @media(max-width: 768px) {
      margin-top: 4px;
      font-size: 16px;
    }
  }
}

.selectedLabel {
  margin-left: 10px;
}

.coverInputBox {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  align-items: center;

  @media(max-width: 920px) {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 14px;
  }
  &>div {
    @media(max-width: 920px) {
      width: 100%;
    }
  }

  & .sportsSelect {
    width: 200px;

    @media(max-width: 920px) {
      width: 100%;
    }

    & :global(.css-tr4s17-option) {
      background-color: #105446;
    }

    & :global(.css-d7l1ni-option) {
      background-color: #10544659;
    }
  }

  & .inputBox {
    width: 200px;
    flex-wrap: wrap;

    @media(max-width: 920px) {
      width: 100%;
    }

    &>div {
      border-bottom: none;

      &:nth-child(1) {
        & svg {
          display: none;
        }
      }
    }

    & select {
      padding-left: 31px !important;
    }

    & select,
    & input {
      height: 36px;
      padding: 0 14px;
      border-radius: 5px;

      &:hover,
      &:focus {
        border: 1px solid #B0B0B0;
      }
    }
  }

  & button {
    width: 121px;
    min-height: 32px;
    height: 32px;
    background-color: #105446;
    border-radius: 50px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media(max-width: 920px) {
      margin-top: 3px;
      width: 100%;
    }
    
    &:disabled{
      opacity: 0.5;
    }
  }
}
& :global(.css-1jqq78o-placeholder) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchbarAssembler {
  color: #B0B0B0;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 14px;
  cursor: pointer;
}

.sportDropdown {
  position: relative;

  @media(max-width: 920px) {
    width: 70%;
    margin: 0 auto;
  }

  @media(max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }

  @media(max-width: 500px) {
    width: 100%;
    margin: 0 auto;
  }

  & span {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.locationWrapper {
  @media(max-width: 920px) {
    width: 70%;
    margin: 0 auto;
  }

  @media(max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }

  @media(max-width: 500px) {
    width: 100%;
    margin: 0 auto;
  }
}