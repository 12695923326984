@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
  padding: 0;
}

.footer {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  /* padding: 24px 32px; */

}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  font-size: 16px;
  line-height: 24px;
}

.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoImage {
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 350px) {

  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {

  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.studentFooter {
  background-color: #105446;

  & .footerGrid {
    padding: 36px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width:1500px) {
      max-width: 1440px;
      margin: 0 auto;
    }

    @media (max-width:768px) {
      margin-bottom: 60px;
    }
  }

  & .gridList {
    display: flex;
    row-gap: 14px;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 48px;

    /* @media (max-width:768px) {
      gap: 10px;
    } */

    & span {
      & a {
        color: #F6F5EC;
        font-size: 15px;
        font-weight: 300;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          right: -25px;
          bottom: 12px;
          background-color: #F6F5EC;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }

      color: #F6F5EC;
      font-size: 15px;
      font-weight: 300;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 3px;
        border-radius: 100%;
        right: -25px;
        bottom: 12px;
        background-color: #F6F5EC;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  & .logoSection {
    @media (max-width:768px) {
      display: none;
    }
  }
}

:global(#footer) {
  &>div {
    padding: 0;
  }
}

.SectionFooter {
  background-color: #105446;

  & .footerGrid {
    padding: 36px 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width:1500px) {
      max-width: 1440px;
      margin: 0 auto;
      padding: 36px 0;
    }

    @media (max-width:500px) {
      padding: 36px;
    }
  }

  & .gridList {
    display: flex;
    row-gap: 14px;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 48px;

    /* @media (max-width:768px) {
      gap: 10px;
    } */

    & span {
      & a {
        color: #F6F5EC;
        font-size: 15px;
        font-weight: 300;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          right: -25px;
          bottom: 12px;
          background-color: #F6F5EC;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }

      color: #F6F5EC;
      font-size: 15px;
      font-weight: 300;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 3px;
        border-radius: 100%;
        right: -25px;
        bottom: 12px;
        background-color: #F6F5EC;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  & .logoSection {
    @media (max-width:768px) {
      display: none;
    }
  }
}

:global(#footer) {
  &>div {
    padding: 0;
  }
}