.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* .main {
  display: grid;
} */

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeSearchBar {
  @media(max-width: 1200px) {
    width: 70%;
  }

  @media(max-width: 1024px) {
    width: 100%;
  }

  @media(max-width: 920px) {
    width: 70%;
    margin: 0 auto;
  }

  @media(max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    padding-top: 54px;
  }

  @media(max-width: 600px) {
    width: 100%;
    margin: 0 auto;
  }
}

.main {
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-image: url(../../assets/images/homecover.png);
  padding: 0 216px;
  display: flex;
  align-items: center;
  background-position: bottom 50px right 51px;

  @media(max-width: 1440px) {
    padding: 0 102px;
    background-position: bottom 50px right -30px;
  }

  @media (max-width:1200px) {
    background-position: bottom 108px right -30px;
  }

  @media(max-width: 1024px) {
    background-image: none;
    background-color: #fff;
  }

  @media(max-width: 920px) {
    /* height: 500px; */
    flex-wrap: wrap;
    justify-content: center;
  }

  @media(max-width: 768px) {
    padding: 0 24px;
    align-items: flex-start;
  }

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.mainPageBuilder {
  display: grid;
}

